.br-20 {
  border-radius: 20px;
}

.mt-6px {
  margin-top: 6px;
}

.red {
  color: red;
}

.link-disabled {
  pointer-events: none;
  cursor: default;
  color: $grey4;
}
