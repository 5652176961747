$font-size-main: 1rem;
$font-size-smaller: 0.9rem;
$font-size-header: 2rem;
$font-size-subheader: 1.2rem;

.link-with-icon {
  display: inline-flex;
  background: $bg-main;
  border-radius: 10px;
  padding: 2px;
}

body {
  margin: 0;
  font-family: 'vc-honey', 'WorkSans', sans-serif;
  font-weight: 300;
  color: $font-color-main;
  font-size: $font-size-main;
}

button {
  font-family: 'Urbanist', 'WorkSans', sans-serif !important;
  border-radius: 50px;
}

.bg-yellow {
  background-color: $bg-main;
}

.bg-new-yellow {
  background-color: $bg-submain;
}

.bg-grey {
  background-color: $grey;
}

a {
  color: $link-color;
  font-size: $font-size-smaller;
  font-weight: 400;
  display: inline-block;
  transition: 0.3s;
  text-decoration: none;
  &:hover {
    color: $link-color-hover;
  }
}

p {
  font-family: 'satoshi', sans-serif;
  color: #3c322f;
  font-weight: 400;
}

h2 {
  font-family: 'vc-honey', sans-serif;
  font-weight: 600;
  font-size: $font-size-header;
  color: $header-color;
}

h3 {
  font-weight: 500;
  font-size: $font-size-subheader;
  color: $header-color;
  margin: 0;
}

.error {
  label {
    color: red !important;
  }
  .p-inputtext,
  .p-dropdown {
    &:enabled:hover {
      border-color: red;
    }
    &::placeholder {
      color: red;
    }
    border-color: red;
    .p-dropdown-label {
      color: red;
    }
  }
}

.p-inputtext {
  padding: 12px 20px;
}

.p-inputtext,
.p-dropdown {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  border-radius: 10px;
  border: 1px solid var(--Lines, #b3b9c3);
  box-sizing: border-box;
}
.p-inputtext.p-autocomplete {
  padding: 5px;
}
.p-inputtext.p-autocomplete > div {
  width: 100%;
  border: none;
}
textarea.p-inputtextarea {
  width: 100%;
}

.p-checkbox-box.p-highlight {
  border-color: $brown1 !important;
  background-color: $brown1 !important;
}

.p-checkbox-box.p-focus {
  border-color: $brown1 !important;
}

.grey-checkbox .p-checkbox-box.p-highlight {
  border-color: $grey4 !important;
  background-color: $grey4 !important;
}

.input-error-text,
.form-input-field__error {
  position: absolute;
  //bottom: -20px;
  right: 0px;
  color: red;
  font-size: 14px;
  text-align: right;
  margin-top: 3px;
}
.form-input-field__error {
  font-style: normal;
}
.input-error-text.-mt-1\.5 {
  margin-top: -1.5rem;
}

.p-radiobutton-icon {
  background-color: $blue1 !important;
  border: 1px solid white;
}

.p-radiobutton.p-radiobutton-checked .p-radiobutton-box {
  border: 2px solid $blue1;
  background-color: $blue1;
}

.p-radiobutton.p-focus .p-radiobutton-box {
  border: 2px solid $blue1;
}

div.error .p-input-icon-right > i:last-of-type {
  color: $red1;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: $blue1;
  background: $blue1;
  color: #ffffff;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: $blue1;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: $blue1;
  background: $blue1;
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  transform: translateZ(0) scale(1.5, 1.5);
  visibility: visible;
}

.p-button.p-button-secondary {
  background-color: $grey2;
  border: none;
  color: $header-color;
  &:hover {
    background-color: $grey2 !important;
    color: $header-color !important;
    opacity: 0.8;
  }
}

.p-button.p-button-link {
  color: $blue1;
}

div.subscription-container {
  max-width: 775px;

  p.price {
    color: $blue1;
  }
  span.not-active {
    color: $grey4;
  }
  div.not-active {
    p.price {
      color: $grey4;
    }
  }
  div.total {
    color: $grey3;
    border-bottom: 1px dotted $grey4;
    p.price {
      color: $header-color;
    }
  }
}

.elula-accordion {
  .elula-accordion-tab {
    .p-accordion-header-link {
      background: #fff;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px dotted $grey2;
    }
    .p-accordion-content {
      border: none;
    }
  }
}

.p-dialog .p-dialog-content {
  overflow: visible;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 1.75rem;
  font-weight: 700;
  text-align: center;
}
.info-component {
  background: $yellow3;
  border-radius: 8px;
  border-left: $yellow4 4px solid;
  max-width: 978px;
}

.border-bottom-grey1 {
  border-bottom: 2px solid $grey4;
}

.border-bottom-grey2 {
  border-bottom: 1px solid $grey2;
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.slide-out-left {
  animation: slideOutLeft 0.5s forwards;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-from-right {
  animation: slideInFromRight 0.5s forwards;
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.slide-out-right {
  animation: slideOutRight 0.5s forwards;
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-from-left {
  animation: slideInFromLeft 0.5s forwards;
}

button.disable-borders {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

textarea.disable-borders {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.no-scroll {
  overflow: hidden;
}

.p-button.error {
  border: 1px solid red;
}
