.p-dropdown {
  line-height: 1.1rem;
}

.p-password {
  width: 100%;
  .p-inputtext {
    width: 100%;
  }
}

.p-icon {
  width: 1.2rem;
  height: 1.2rem;
}
.p-button {
  background: rgba(70, 142, 208, 1);
  border: 1px solid rgba(70, 142, 208, 1);
  border-radius: 50px;
}

.ai-assistant-content {
  overflow: hidden;
  img {
    max-width: 100%;
  }
}

.ai-assistant-content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
  border: 1px solid $blue1;
  text-align: center;

  thead {
    background-color: $blue4;
  }

  tr,
  td,
  th {
    border: 1px solid $blue1;
    text-align: center;
  }
}

.p-datepicker-touch-ui,
.p-calendar .p-datepicker-touch-ui {
  min-width: auto;
}

.p-datepicker-title button {
  color: $blue1 !important;
}

.simple-keyboard {
  background: $blue5; /* Background color for the keyboard */
}

.simple-keyboard .hg-button {
  background: $blue1; /* Background color for the buttons */
  color: #fff; /* Text color for the buttons */
  border: 1px solid $blue2; /* Border color for the buttons */
}

.simple-keyboard .hg-button:hover {
  background: $blue2; /* Background color for the buttons on hover */
}
/* Disable hover styles for touch devices */
@media (hover: none) {
  .simple-keyboard .hg-button:hover {
    background: $blue1; /* Reset hover background */
  }
}

.simple-keyboard .hg-button:active {
  background: $blue4; /* Background color for the buttons on active */
}

.hg-theme-default {
  border-radius: 0;
}
