@media only screen and (min-width: 800px) {
  .md-flex {
    display: flex;
  }

  .logo {
    width: 20vw;
    height: auto;
  }

  .md-part {
    flex: 1;
  }

  .md-center {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }

  .md-p-100 {
    padding: 100px;
  }
}

@media only screen and (max-width: 800px) {
  .sm-fixed {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    width: 40vw;
    height: auto;
  }

  .sm-p-20 {
    padding: 20px;
  }
}
@media (min-width: 640px) {
  /* sm: styles go here */
}

@media (min-width: 768px) {
  .intro-bg {
    background-image: url('../app/images/intro-background-dectop.png');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
  }
  .welcome-bg {
    background-image: url('../app/images/welcome-background-dectop.png');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
  }
  .register-bg {
    background-image: url('../app/images/Group 44.svg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .register-steps .content-wrap {
    background-image: none;
  }
}
@media (min-width: 1024px) {
  .register-steps {
    & .register-steps-title {
      font-size: 36px;
      line-height: 54px;
    }
  }
}
