@use 'three-dots' with (
  $dot-width: 6px,
  $dot-height: 6px,

  $dot-color: #468ed0
);

@font-face {
  font-family: 'satoshi';
  src: url('../public/fonts/Satoshi-Regular.woff') format('woff');

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'vc-honey';
  src: url('../public/fonts/VCHoney.woff') format('woff');

  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@500;600;700&family=Work+Sans:wght@400;500;600;700&display=swap');

@tailwind utilities;
@tailwind components;
@import './styles/colors.scss';
@import './styles/theme.scss';
@import './styles/utils.scss';
@import './styles/elements.scss';
@import './styles/responsive.scss';
@import './styles/overrides.scss';
